import { useDeviceQuery } from "@/utils";
import { Card, Flex, List, Text, Title, rem } from "@mantine/core";
import { useTranslation } from "next-i18next";
import IconCard from "@/public/icons/icon-cart-check.svg";
import IconQrCode from "@/public/icons/icon-qr-code.svg";
import IconTickets from "@/public/icons/icon-tickets.svg";

export default function HowToOrder(): JSX.Element {
  const { t } = useTranslation();
  const [isMobile] = useDeviceQuery();

  return (
    <>
      <Flex
        gap={{ base: rem(10), md: rem(31) }}
        direction={{ base: "column", md: "row" }}
      >
        <Card
          withBorder
          radius="md"
          className="border-red-1 md:max-md:w-1/3 w-full"
          h={rem(272)}
        >
          <Flex h="100%" justify="center" align="center" direction="column">
            <IconCard />
            <Title order={5} fw={700} mt={rem(20)} mb={rem(10)} c="dark.9">
              01. {t("top:sections.boarding.steps.0.title")}
            </Title>
            <Text size="md" c="dark.8" ta="center">
              {t("top:sections.boarding.steps.0.description")}
            </Text>
          </Flex>
        </Card>
        <Card
          withBorder
          radius="md"
          className="border-red-1 md:max-md:w-1/3 w-full"
          h={rem(272)}
        >
          <Flex h="100%" justify="center" align="center" direction="column">
            <IconQrCode />
            <Title order={5} fw={700} mt={rem(20)} mb={rem(10)} c="dark.9">
              02. {t("top:sections.boarding.steps.1.title")}
            </Title>
            <Text size="md" c="dark.8" ta="center">
              {t("top:sections.boarding.steps.1.description")}
            </Text>
          </Flex>
        </Card>
        <Card
          withBorder
          radius="md"
          className="border-red-1 md:max-md:w-1/3 w-full"
          h={rem(272)}
        >
          <Flex h="100%" justify="center" align="center" direction="column">
            <IconTickets />
            <Title order={5} fw={700} mt={rem(20)} mb={rem(10)} c="dark.9">
              03. {t("top:sections.boarding.steps.2.title")}
            </Title>
            <Text size="md" c="dark.8" ta="center">
              {t("top:sections.boarding.steps.2.description")}
            </Text>
          </Flex>
        </Card>
      </Flex>
      <List
        classNames={{
          root: "text-red-9",
        }}
        my={rem(15)}
        w="95%"
      >
        <List.Item>
          <Text size="sm" c="red.9">
            {t("top:sections.boarding.remarks.0")}
          </Text>
        </List.Item>
        <List.Item>
          <Text size="sm" c="red.9">
            {t("top:sections.boarding.remarks.1")}
          </Text>
        </List.Item>
      </List>
    </>
  );
}
